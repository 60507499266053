export const columnAnimations = [
    {
        start: 0,
        end: 400
    },
    {
        start: 100,
        end: 200
    },
    {
        start: 0,
        end: 700
    },
    {
        start: 0,
        end: 100
    },
    {
        start: 0,
        end: 500
    },
]