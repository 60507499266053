import React, { useState, useEffect, useRef } from 'react'

import { fetchGallery } from '../Logic/api'
import { PortfolioProps, Filters, PortfolioData, getImageSize, FormatDetail } from '../types'
import { lockSprawl, isSprawlLocked } from '../sprawl'
import HoverImage from '../components/HoverImage'
import { BREAK_POINTS } from '../Config'

const Portfolio = (props: PortfolioProps) => {
    lockSprawl()

    const [color,updateColor] = useState(Filters.none)
    const [loaded, updateLoaded] = useState(false)
    const [data,updateData] = useState({} as PortfolioData)
    const [gid,updateGID] = useState("")
    const [fetching,updateFetching] = useState(false)
    const [maxPhotos,updateMaxPhotos] = useState(
        window.innerWidth <= BREAK_POINTS.small ? 2 : 5
    )

    const photos = useRef()

    // this makes sure we don't get stuck on the portfolio
    // useEffect(()=>{
    //     if(props.reset){
    //         updateLoaded(false)
    //         props.updateReset(false)
    //         updateData({} as PortfolioData)
    //         updateFetching(false)
    //     }
    // },[props.reset])

    // if(!data.id && !fetching){
    //     updateFetching(true)
    //     fetchGallery(props.id)
    //         .then((data: PortfolioData)=>{
    //             updateGID(props.id)
    //             updateData(data)
    //             updateLoaded(true)
    //         })
    // }

    // console.log(props.id,gid)
    // useEffect(()=>{
    //     if(props.id != gid && !fetching){
    //         updateFetching(true)
    //         fetchGallery(props.id)
    //             .then((data: PortfolioData)=>{
    //                 updateData(data)
    //                 updateGID(props.id)
    //                 updateLoaded(true)
    //             })
    //     }
    // })

    useEffect(()=>{
        if(data.Photos){
            updateLoaded(true)
            setTimeout(()=>{
                updateMaxPhotos(data.Photos.length)
            },2000)
        }else{
        }
    },[data])
    useEffect(()=>{
        updateLoaded(false)
        updateFetching(true)
        fetchGallery(props.id)
            .then((data: PortfolioData)=>{
                updateGID(props.id)
                updateData(data)
                updateLoaded(true)
            })
    },[props.id])

    return(
        <div className="PortfolioPage">
            {
                !loaded && (
                    <div id="loading">
                        <div>loading...</div>
                    </div>
                )
            }
            { loaded && (
            <div className="Portfolio" ref={photos}>
                <div id="about">
                    <div id="aboutinfogroup">
                        <div id="aboutTitle">
                          {data.Title}  
                        </div>
                        <div id="text">
                            {data.Description}
                        </div>
                    </div>
                </div>
                <div id="photos" >
                    {
                        data.Photos.slice(0,maxPhotos).map((item,idx: number)=>{
                            let image: FormatDetail
                            if(window.innerWidth < BREAK_POINTS.large)  image = getImageSize(item.formats,item.url)
                            else  image = { url: item.url }
                            
                            return <HoverImage blurUrl={item.formats.thumbnail.url} key={idx} idx={idx} largeUrl={item.url} imgUrl={image.url} desc={item.caption} />
                        })
                    }
                </div>
            </div>
            )}
        </div>
    )
}

export default Portfolio
