import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FiltersMatrix, PhotoColumnProps, PhotoColumnPhotoProps, Filters, ColImage, StrapiPhoto, getImageSize } from '../types'
import ImageFilter from 'react-image-filter';

var isInViewport = function (elem,idx) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const PhotoColumnPhoto = (props:PhotoColumnPhotoProps) => {
    const ref = useRef(null)
    const [loaded,updateLoaded] = useState(true)

    // props.updateFn(props.idx,updateLoad)

    // if(ref.current)
    //     if(ref.current.loading != "lazy")
    //         ref.current.loading = "lazy"

    // if(ref.current) ref.current.onload = ()=>{ updateLoaded(true) } 
    // if(ref.current && !loaded && ref.current.complete) updateLoaded(true)

    // setTimeout(()=>{updateLoaded(true)},2500)

    return(
        <div ref={ref} className={`PhotoColumnPhoto ${!loaded ? "blur" : ""}`} >
                        <div onClick={()=>{props.goToGallery(props.img)}} className="cimgc">
                                <ImageFilter
                                    className="cimg"
                                    image={!loaded ? props.blurUrl : props.url}
                                    filter={
                                        props.gallery === props.id ?
                                        props.filter : FiltersMatrix.none
                                    }
                                />
                        </div>
        </div>
    )
}

export default React.memo(PhotoColumnPhoto)