import React, { useEffect, useRef, useState } from 'react'
import { navigate } from '@reach/router'
import { Link, SecProps,ItemProps, Filters }  from '../types'

const Section = (props: SecProps) => {
    return(
        <div className="Section">
            <div id="sectionTitle" >
                { 
                   props.section.Title !== "films" && (props.section.Title)
                }
                {
                    props.section.Title === "films" && (
                        <div
                        onClick={async ()=>{
                            props.closeNav()
                            await navigate("/films")
                        }}>
                            {props.section.Title}
                        </div>
                    )
                }
            </div>
            <div>
                { 
                    props.section.Links.map((link: Link, idx:number)=>{
                        return <ItemC closeNav={props.closeNav} registerRef={props.registerRef} blackOut={props.blackOut} gallery={props.gallery} hover={props.hover} link={link} key={idx}/>
                    }) 
                }
            </div>
        </div>
    )
}


export const ItemC = (props: ItemProps) => {
    const [renered,udpateR] = useState(false)
    const item = useRef(null)
    if(item.current && props.gallery && props.link.gallery){
        if(props.gallery==props.link.gallery.id) item.current.style.color = props.link.gallery.color 
        // else if(item.current.style.color!="black")item.current.style.color = Filters.black
    }
    useEffect(()=>{
        if(item.current){
            props.registerRef(props.link.Title,item.current)
        }
    },[item])
    return(
        <div
            ref={item}
            className="Items" 
            onMouseEnter={()=>{
                if(props.link.gallery){
                    props.hover(props.link.gallery.id)
                    item.current.style.color = props.link.gallery.color
                }
            }}
            onTouchEnd={()=>{
                props.hover(props.link.gallery.id)
                item.current.style.color = props.link.gallery.color
            }}
            onMouseLeave={()=>{
                props.hover(Filters.black)
                item.current.style.color = Filters.black
            }}
            onClick={ async (e)=>{ 
                if(window.location.pathname == "/" && window.innerWidth< 780 ){
                    console.log("locking for a second")
                    props.hover(props.link.gallery.id)
                    props.closeNav()
                    item.current.style.color = props.link.gallery.color
                    setTimeout( async ()=>{
                        props.blackOut(props.link.gallery.Title)
                        props.closeNav()
                        props.hover(Filters.black)
                        await navigate( `/${props.link.basePath.path}/${props.link.gallery.id}/${props.link.gallery.Title}` ) 
                    },1000)
                }else{
                    props.blackOut(props.link.gallery.Title)
                    props.closeNav()
                    await navigate( `/${props.link.basePath.path}/${props.link.gallery.id}/${props.link.gallery.Title}` )
                    // only use if no other option
                    // location.reload()
                }
                }
            }
        >
            { props.link.Title }
        </div>
    )
}

export default Section 