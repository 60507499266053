import React, { useState, useEffect, useRef } from 'react'
import anime from 'animejs';

import { HomeProps, Filters, ColImage, Formats } from '../types'
import Navigation from '../components/Navigation'
import PhotoColumn from '../components/PhotoColumn'
import { lockSprawl, unlockSprawl } from '../sprawl'
import { columnAnimations } from '../utilities/animations'

const NOHOME = "NOHOME"

const Home = (props: HomeProps) => {
    const [rawCols, updateRawCols] = useState([] as Array<ColImage>)
    const [numCols, updateNumCols] = useState([[], [], [], [], []] as Array<Array<ColImage>>)
    const [loaded, updateLoaded] = useState( false )

    const imagesToLoad = window.innerWidth < 800 ? 45 * 5 : 45 * 5 // num coloumns
    if (!loaded && props.images.length !== 0){
        console.log("loading")
        // map all photos to rawCols
        props.images.map((p: any) => {
            return p.Photos.map((photo: {formats:Formats, url: string, alternativeText: string }, idx: number) => {
                if (photo.alternativeText == NOHOME) return
                rawCols.push({
                    formats: photo.formats,
                    photo: photo.url,
                    gallery: {
                        id: p.id,
                        name: p.Title,
                        kind: p.kind
                    },
                    color: p.color,
                    url: photo.url
                })
            })
        })

        for(let i=0; i <= imagesToLoad ;i++){
            let randIdx = Math.random() * rawCols.length;
            numCols[ i % 5 ].push(rawCols.splice(randIdx,1)[0])
        }
        updateLoaded(true)
    }

    useEffect(() => {
        if (loaded && props.path === '/sprawl') {
            if (window.innerWidth > 780) {

                let elements = document.getElementsByClassName('App')[0]
                //@ts-ignore
                elements.style.position = "absolute"
                //@ts-ignore
                elements.style.top = "0"
                //@ts-ignore
                elements.style.left = "0"
                let nav = document.getElementsByClassName('Navigation')[0]
                //@ts-ignore
                // nav.style.marginTop = 0

                let marginTop = {}
                if (window.innerWidth < 780) {
                    marginTop = {
                        value: ["0%", "15%"],
                        duration: 1000,
                        easing: 'easeInOutSine'
                    }
                }

                const delay = 500
                anime({
                    targets: nav,
                    fontSize: {
                        value: ['100%', '40%'],
                        duration: 1800,
                        easing: 'easeInOutSine',
                    },
                    delay
                });

                anime({
                    targets: elements,
                    width: {
                        value: ['100%', '20%'],
                        duration: 1800,
                        easing: 'easeInOutSine'
                    },
                    height: {
                        value: ['100%', '20%'],
                        duration: 1800,
                        easing: 'easeInOutSine',
                    },
                    fontSize: {
                        value: ['100%', '55%'],
                        duration: 1800,
                        easing: 'easeInOutSine',
                    },
                    marginTop,
                    delay
                });
                setTimeout(()=>{
                    unlockSprawl()
                },2300)
            }else{
                let nav = document.getElementsByClassName('NavRouter')[0]
                //@ts-ignore
                nav.style.zIndex = 10

                let elements = document.getElementsByClassName('Home')[0]
                // elements.style.position = "absolute"
                // elements.style.top = "0"
                // elements.style.left = "0"

                anime({
                    targets: elements,
                    opacity: {
                        value: ['100%', '0%'],
                        duration: 1800,
                        easing: 'easeInOutSine',
                    },
                    delay:500
                });

                setTimeout(()=>{
                    unlockSprawl()
                },2300)
            }

        } else {
            if(!localStorage.getItem("sprawl")) lockSprawl()
        }
    }, [loaded])

    const ul = (idx:number) => {
    //    loadedCols[idx] = true 
    //    if( loadedCols.every(v=>v) ){
    //        updateLoaded(true)
    //    }
    }

    const style = loaded ? 
    {
        Visibility: "visible",
        width: "100%"
    } :
    {
        Visibility: "hidden",
        width: "0%",
        height: "0%"
    }

    // if(!loaded && numCols.length !== 0) updateLoaded(true)

    return (
        <div className={'Home mainPage'}>
            {
                !loaded && (
                        <div id="loadingAppHome">loading...</div>
                )
            }
                    <div style={style} className={`homeColumns animate__animated animate__fadeIn animate__delay-${window.innerWidth < 800 ? 3 : 3}s`}>
                        {
                            numCols.map((img: Array<ColImage>, idx: number) => {
                                return <PhotoColumn updateLoaded={ul} idx={idx} animation={columnAnimations[(idx + 2) % 5]} gallery={props.gallery} key={idx} images={img} />
                            })
                        }
                    </div>
        </div>
    )
}
// {
//     props.path === "/sprawl" && (
//         <Navigation  sprawl={true} hover={props.hover} sections={props.sections}/>
//     )
// }

export default Home