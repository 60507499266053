export interface Rgba{
    r: number 
    g: number
    b: number 
    a: number
}

let A_MAX = 1
let COLOR_MAX = 255

export const hex2fem = (str: string) => {
    let rgba = convertHexToRGBA(str)
    let template = [
        0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 1, 0.0,
    ];
    let ri = 0
    let gi = 6
    let bi = 12
    let ai = 18

    template[ri] = precision(rgba.r / COLOR_MAX)
    template[gi] = precision(rgba.g / COLOR_MAX)
    template[bi] = precision(rgba.b / COLOR_MAX)
    // template[ai] = precision(rgba.a / COLOR_MAX)

    return template
}

const precision = (num:number): number => {
    // 3 since all values are between 1 & 0 
    return parseFloat(num.toPrecision(3))
}

const convertHexToRGBA = (hexCode) => {
    let hex = hexCode.replace('#', '');
    
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }    
    
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return {r,g,b,a:1.0}
};