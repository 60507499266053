import { RouteComponentProps } from '@reach/router'
import { BREAK_POINTS } from './Config'

export interface HomeProps extends RouteComponentProps {
    sections: Array<Sections>
    images: Array<ColImage>
    gallery: string
    hover?(gallery: string): void
    updateLoaded(b: boolean): void
}

export interface PortfolioProps extends RouteComponentProps {
    id?: string
    reset?: boolean
    updateReset: (r: boolean) => void
}

export interface BooksPageProps extends RouteComponentProps {
    id?: string
}

export interface AboutPageProps extends RouteComponentProps {
}

export interface FilmPageProps extends RouteComponentProps {
}

export interface NavProps extends RouteComponentProps {
    id?: string
    sections: Array<Sections>
    hover(gallery: string): void
    sprawl?: boolean
    updateReset: (r: boolean) => void
}


// export interface NavProps {
//     sections: Array<Sections>
//     hover(gallery: string): void
// }

export interface Link {
    Title: string
    basePath: {
        path: string
    }
    gallery: {
        id: string
        color: string
        Title: string
    }
}

export interface Sections {
    Title: string
    Links: Array<Link>
}


export interface SecProps {
    gallery: string
    section: Sections
    hover(gallery: string): void
    blackOut(cr: string): void
    registerRef(title: string, ref: HTMLElement): void
    closeNav?(): void
}

export interface ItemProps {
    gallery: string
    link: Link
    hover(gallery: string): void
    blackOut(cr: string): void
    registerRef(title: string, ref: HTMLElement): void
    closeNav?(): void
}

// export interface ColImage {
//     id: string
//     Photos: Array<StrapiPhoto>
// }

export interface ColImage {
    formats: Formats
    photo: string
    gallery: {
        id: string,
        name: string,
        kind: string
    }
    color: string
    url: string

}

export interface StrapiPhoto {
    Photo: {
        url: string
    }
    gallery: {
        color: string
        king: string
        Title: string
    }
}

export interface PhotoColumnProps {
    images: Array<ColImage>
    gallery: string
    animation: scrollOpts
    idx: number
    updateLoaded: (idxLoaded: number) => void
}

export interface PhotoColumnPhotoProps{
    url: string 
    gallery: string 
    img: ColImage
    id: string
    loadState:any 
    goToGallery: (img: ColImage) => void
    filter: any
    idx: number
    blur?: boolean
    blurUrl: string
    updateFn: (idx:number,fn:()=>boolean) => void 
}

export interface scrollOpts {
    start: number
    end: number
}

export interface PhotoProps {
    image: string
    filter: Filters
}

export interface Cord {
    x: number,
    y: number
}


export interface TCords {
    tl: Cord
    tr: Cord
    bl: Cord
    br: Cord
}

export interface TDeltaPoint {
    newPos: Cord
    oldPos: Cord
}

export interface PortfolioData {
    id: string
    Title: string
    Description: string
    Photos: Array<{
        formats: Formats
        url: string
        caption: string
    }>
}

export interface Photo {
    formats: Formats
    url: string
    caption: string
}

export interface Formats {
    thumbnail?: FormatDetail
    small?: FormatDetail
    medium?: FormatDetail
    large?: FormatDetail
}

export interface FormatDetail {
    name?: string
    url: string
}

export enum Filters {
    red = 'red',
    blue = 'blue',
    green = 'green',
    orange = 'orange',
    none = 'none',
    black = 'black'
}

export const FiltersMatrix = {
    red: [
        1, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 1, 0
    ],
    green: [
        0, 0, 0, 0, 0,
        0, 1, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 1, 0
    ],
    orange: [
        0.97, 0, 0, 0, 0,
        0, 0.39, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 0, 1, 0
    ],
    blue: [
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0, 0, 1, 0, 0,
        0, 0, 0, 1, 0,
    ],
    none: [
        1, 0, 0, 0, 0,
        0, 1, 0, 0, 0,
        0, 0, 1, 0, 0,
        0, 0, 0, 1, 0,
    ]
}

export interface HoverImageProps {
    idx: number
    imgUrl: string
    desc: string
    blurUrl: string
    largeUrl: string 
}

export interface SprawlData {
    sprawl: {
        photos: Array<{
            formats: Formats
            url: string
            caption: string
        }>
    }
    sprawlLinks: Array<{
        Title: string
        Url: string
        color: string
    }>
}

export interface FilmData {
    Title: string
    Detail: string
    Url: string
}

export interface AboutData {
    header: string
    detail: string
}

export const getImageSize = (photo: Formats,fallback: string) => {
        if(photo===undefined) return { url: "" }

        let w = window.innerWidth
        if(w <= BREAK_POINTS.small && photo.small){
            return photo.large
        }

        if(w <= BREAK_POINTS.medium && photo.medium){
            return photo.large
        }

        if (w >= BREAK_POINTS.large && photo.large){
            return photo.large
        }

        // try smaller sizes before returning raw image

        if(photo.medium) return photo.medium
        if(photo.small) return photo.small

        return { url: fallback }
    }

export interface BookPhotoProps{
    blurUrl: string
    url: string
    largeUrl: string
}