export const fetchColumns = `
query{
    columns{
        id
        Photos{
            formats
            Photo{
                url
            }
            gallery{
                id
                color
                kind
                Title
            }
        }
    }
}
`

export const fetchNavigation = `
query{
    sections{
        Title
        Links{
            Title
            basePath{
                path
            }
            gallery{
                id
                color
                Title
            }
        }
    }
}
`

// restult is {data:{ gallery:null }} when none found
export const fetchGallery = (gallery_id: string) => `
query{
    gallery(id:"${gallery_id}"){
        id
        Title
        Description
        Photos{
            formats
            url
            caption
        }
        kind
        color
    }
}
`

export const getSprawl = `
query{
    sprawl{
        photos{
            formats
            url
            caption
        }
    }
    sprawlLinks{
        Title
        Url
        color
    }
}
`


export const fetchPhotos = `
query{
    galleries{
        id
        Title
        color
        kind
        Photos{
            formats
            url
            alternativeText
        }
    }
}
`

export const fetchFilms = `
query{
    films{
        Title
        Detail
        Url
    }
}
`

export const fetchAbout = `
query{
    about{
        header
        detail
    }
}
`