import { fabric } from 'fabric-with-gestures'
import { TCords } from './'
import { SprawlData } from './types'
import { sampleImages } from '../sampleData'

// consolere.connect({
//     server: 'https://console.re', // optional, default: https://console.re
//     channel: '7cc4-2720-4aba', // required
//     redirectDefaultConsoleToRemote: true, // optional, default: false
//     disableDefaultConsoleOutput: false, // optional, default: false
// });

const MOBILE_BREAKPOINT = 780
const CANVAS_WIDTH = window.innerWidth
const CANVAS_HEIGHT = window.innerHeight

let canvasGlobal

export const setupCanvasMobile = async (cref: HTMLCanvasElement, data: SprawlData) => {
    console.log("setup canvas mobile")
    // const MULTIPLIER = 2
    const MULTIPLIER =  1
    console.log(`CANVAS HEIGHT ${CANVAS_HEIGHT}\nCANVAS WIDTH ${CANVAS_WIDTH}\nCANVAS AREA ${CANVAS_HEIGHT * MULTIPLIER * CANVAS_WIDTH * MULTIPLIER}`)
    // canvas & bg
    let canvas = new fabric.Canvas(cref, {
        backgroundColor: 'white',
        hoverCursor: 'pointer',
        width: CANVAS_WIDTH, //* MULTIPLIER,
        height: CANVAS_HEIGHT, //* MULTIPLIER,
        preserveObjectStacking: true,
        // enableRetinaScaling: false
    });

    canvasGlobal = canvas

    let bg = new fabric.Rect({
        backgroundColor: "white",
        selectable: false,
        // hoverCursor: "grab",
        fill: 'white',
        width: CANVAS_WIDTH, //* MULTIPLIER,
        height: CANVAS_HEIGHT, //* MULTIPLIER,
        top: 0,
        left: 0
    })
    canvas.renderAll()

    // setup grid
    let grid = 5
    let canvasWidth = CANVAS_WIDTH *  2//* MULTIPLIER * 3
    let canvasHeight = CANVAS_HEIGHT  * 2//* MULTIPLIER * 3
    for (var i = -(canvasWidth / grid); i < (canvasWidth / grid); i++) {
        //horiontal
        canvas.add(new fabric.Line([i * grid, -canvasWidth, i * grid, canvasWidth], { type: 'line', stroke: 'blue', strokeWidth: 0.025, opacity: 0.1, selectable: false }));
        //vertical
        canvas.add(new fabric.Line([-canvasWidth, i * grid, canvasHeight, i * grid], { type: 'line', stroke: 'blue', strokeWidth: 0.025, opacity: 0.1, selectable: false }))
    }

    canvas.add(bg)
    // bg.viewportCenter()
    // add images
    const addImg = (img) => {
        // let top = Math.abs(CANVAS_HEIGHT * MULTIPLIER * Math.random() - 100)
        // let left = Math.abs(CANVAS_WIDTH * MULTIPLIER * Math.random() - 100)
        let top = Math.abs(CANVAS_HEIGHT * MULTIPLIER * Math.random() - 10 )
        let left = Math.abs(CANVAS_WIDTH * MULTIPLIER * Math.random() - 10 )

        let i = img.set({
            top,
            left
        })
        i.scale(0.05)
        i.set("selectable", false)
        canvas.add(i)
    }

    const openNewTab = (url:string) => {
        let button = document.createElement('button')
        button.style.width = "0"
        button.style.height = "0"
        button.style.visibility = "hidden"

        button.onclick = function(){
            try{
                window.open("https://www.losstudios.xyz","_blank")
            }catch(e){
                console.log(e)
            }
        }

        document.body.appendChild(button)

        button.click()
    }

    const addTxt = (txt: string, url: string, color: string = "#000000") => {
        let text = new fabric.Text(`${txt} / go to desktop for full experience`, {
            // fontFamily: 'gruppo',
            top: Math.abs(CANVAS_HEIGHT * MULTIPLIER * Math.random() - 200),
            left: Math.abs(CANVAS_WIDTH * MULTIPLIER * Math.random() - 200),
            fontSize: 15,
            backgroundColor: color,
            fill: 'white',
            // selectable: false
        })

        text.hasControls = false
        text.hasBorders = false

        text.on("selected", () => {
            openNewTab(url)
            window.open(url,"_blank")
            window.focus()
        })

        text.scale(0.5)

        canvas.add(text)
        canvas.bringForward(text)

        return text
    }

    let mainImg = data.sprawl.photos.splice(0,1)[0]
    fabric.Image.fromURL(mainImg.url,(img)=>{
        img.set({
            top: 10,
            left: 10
        })
        img.scale(0.01)
        img.set("selectable",false)

        img.on("selected",()=>{
        })

        canvas.add(img)
    })


    for (let i of data.sprawl.photos) {
        // fabric.Image.fromURL(i.url, addImg);
        if(!i.formats){
            continue
        }
        let url = i.formats.small ? i.formats.small.url : i.formats.medium ? i.formats.medium.url : i.formats.thumbnail.url
        fabric.Image.fromURL(url, addImg);
    }

    sleep(3000).then(() => {
        addTxt("created by los_studios", "http://www.losstudios.xyz")
        for (let t of data.sprawlLinks) {
            let text = addTxt(t.Title, t.Url, t.color)
            // text.on("touch:longpress", (e) => {
            //     alert('sdfsd')
            // })
        }
    })

    canvas.setZoom(10)
    // canvas.setZoom(0.75)

    //control
    canvas.on('mouse:move', function (opt) {
    //     if (this.isDragging) {
    //         // console.log('dragging');
    //         var e = opt.e;
    //         var vpt = this.viewportTransform;

    //         console.log("mouse")

    //         // check box coordinates
    //         // let vptCords = canvas.vptCoords
    //         // console.log(JSON.stringify(vptCords));

    //         //@ts-ignore
    //         // console.log(e.touches.length)
    //         //@ts-ignore
    //         if(e.touches.length === 1 ){
    //         //@ts-ignore
    //             let newX = e.touches[0].clientX - this.lastPosX
    //         //@ts-ignore
    //             let newY = e.touches[0].clientY - this.lastPosY

    //             let vp = canvas.calcViewportBoundaries()

    //             // top 
    //                 if( vp.tl.y - newY < -1700)  newY = 0
    //             // left
    //                 if( vp.tl.x - newX < -1000) newX = 0
    //             // bottom
    //                 if (vp.br.y - newY > 4900) newY = 0
    //             //right
    //                 if (vp.br.x - newX > 2800) newX = 0

    //             vpt[4] += newX || 0
    //             vpt[5] += newY || 0
    //             this.requestRenderAll();
    //         //@ts-ignore
    //             this.lastPosX = e.touches[0].clientX
    //         //@ts-ignore
    //             this.lastPosY = e.touches[0].clientY
    //             // console.log(this.lastPosX,newX)
    //         }


    //         //@ts-ignore
    //         if(e.touches.length === 2){
    //             this.dragging = false;
    //         //@ts-ignore
    //             var point = new fabric.Point(e.touches[0].clientX, e.touches[0].clientY);
    //             // let zoomStartScale = self.canvas.getZoom();

    //             // var delta = zoomStartScale * e.self.scale;
    //             // self.canvas.zoomToPoint(point, delta);
    //             // pausePanning = false;
    //         }
    //     }
    });

    canvas.on('mouse:up', function (opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        // console.log('no draggin')
        this.isDragging = false;
        this.selection = true;
        // bg.hoverCursor = "grab"
    });
    canvas.on('mouse:down', function (opt) {
        var evt = opt.e;
        //   if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        //@ts-ignore
        this.lastPosX = evt.clientX;
        //@ts-ignore
        this.lastPosY = evt.clientY;
        //   }
    });

    let pausePanning, zoomStartScale
    pausePanning = false

    canvas.on({
        'touch:gesture': function (e) {
            if (e.e.touches && e.e.touches.length == 2) {
                pausePanning = true;
                var point = new fabric.Point(e.self.x, e.self.y);
                if (e.self.state == "start") {
                    zoomStartScale = canvas.getZoom();
                }
                var delta = zoomStartScale * e.self.scale;
                if (delta <= 2){
                    // canvas.zoomToPoint(point, 2.1);
                    pausePanning= false
                    return
                }
                canvas.zoomToPoint(point, delta);
                pausePanning = false;
            }
            else{
            }
        },
        'object:selected': function () {
            pausePanning = true;
        },
        'selection:cleared': function () {
            pausePanning = false;
        },
        'touch:drag': function (e) {
            if (pausePanning == false && undefined != e.e.layerX && undefined != e.e.layerY) {
                let currentX = e.e.layerX;
                let currentY = e.e.layerY;
                let xChange = currentX - this.lastPosX;
                let yChange = currentY - this.lastPosY;


                let vp = canvas.calcViewportBoundaries()
                // top 
                if (vp.tl.y - yChange < -canvasHeight - 100) yChange = 0
                // // left
                if (vp.tl.x - xChange < -canvasWidth - 100) xChange = 0
                // // bottom
                if (vp.br.y - yChange > canvasHeight + 100) yChange = 0
                // //right
                if (vp.br.x - xChange > canvasWidth + 100) xChange = 0


                if ((Math.abs(currentX - this.lastPosX) <= 50) && (Math.abs(currentY - this.lastPosY) <= 50)) {
                    var delta = new fabric.Point(xChange, yChange);
                    canvas.relativePan(delta);
                }

                this.lastPosX = e.e.layerX;
                this.lastPosY = e.e.layerY;
            }
        }
    });

    canvas.renderAll()
}

export const setupCanvas = (cref: HTMLCanvasElement, data: SprawlData) => {
    console.log()
    // const MULTIPLIER = 3
    const MULTIPLIER = 1
    if (CANVAS_WIDTH < MOBILE_BREAKPOINT) {
        setupCanvasMobile(cref, data)
        return
    }
    console.log(`Canvas Dimmensions: W=${CANVAS_WIDTH * MULTIPLIER} H=${CANVAS_HEIGHT * MULTIPLIER}`);

    let canvas = new fabric.Canvas(cref, {
        backgroundColor: 'white',
        // hoverCursor: 'pointer',
        width: CANVAS_WIDTH,//* MULTIPLIER,
        height: CANVAS_HEIGHT,//* MULTIPLIER,
    });

    canvasGlobal = canvas
    let bg = new fabric.Rect({
        backgroundColor: "white",
        selectable: false,
        hoverCursor: "grab",
        fill: 'white',
        width: CANVAS_WIDTH, //* MULTIPLIER,
        height: CANVAS_HEIGHT// * MULTIPLIER,
    })

    bg.viewportCenter()

    let horiontal = bg.getBoundingRect().width / 2
    let vertical = -bg.getBoundingRect().height / 2
    // canvas.setViewportTransform([1, 0, 0, 1, -2000, -700])
    // canvas.setViewportTransform([1, 0, 0, 1, -2000, -700])

    let canvasWidth = CANVAS_WIDTH  * 2//* MULTIPLIER * 2
    let canvasHeight = CANVAS_HEIGHT * 2 // * MULTIPLIER * 2

    let grid = 5
    for (var i = -(canvasWidth / grid); i < (canvasWidth / grid); i++) {
        // canvas.add(new fabric.Line([ i * grid, -canvasWidth, i * grid, canvasWidth], { type:'line', stroke: 'blue',strokeWidth:1, opacity:0.2 ,selectable: false }));
        //vertical
        canvas.add(new fabric.Line([i * grid, -canvasWidth , i * grid, canvasWidth * 2], { type: 'line', stroke: 'blue', strokeWidth: 0.25, opacity: 0.05, selectable: false }));
        //horiontal
        canvas.add(new fabric.Line([-canvasHeight , i * grid, canvasHeight * 2, i * grid], { type: 'line', stroke: 'blue', strokeWidth: 0.25, opacity: 0.05, selectable: false }))
    }
    console.log('done with canvas')

    canvas.add(bg)

    let numCircles = [...Array(200).keys()]

    let circles = []

    const inBox = (top: number, left: number) => {
        // while (top <= 258 && left <= 480) {
        //     if (top <= 258) top = Math.abs(CANVAS_HEIGHT * MULTIPLIER * Math.random() - 100)
        //     if (left <= 480) left = Math.abs(CANVAS_WIDTH * MULTIPLIER * Math.random() - 100 + 480)
        // }

        return { top, left }
    }

    function CenterCoord() {
        let zoom = canvas.getZoom()
        return {
            x: fabric.util.invertTransform(canvas.viewportTransform)[4] + (canvas.width / zoom) / 2,
            y: fabric.util.invertTransform(canvas.viewportTransform)[5] + (canvas.height / zoom) / 2
        }
    }

    const addImg = (img) => {
        let { top, left } = inBox(
            Math.abs(CANVAS_HEIGHT * MULTIPLIER * Math.random() - 100),
            Math.abs(CANVAS_WIDTH * MULTIPLIER * Math.random() - 100)
        )

        let i = img.set({
            top,
            left
        })

        i.scale(0.1)
        i.set("selectable", false)
        i.set("hoverCursor", "grab")
        canvas.add(i)
        circles.push(i)
    }

    const addTxt = (txt: string, url: string, color: string = "#000000") => {
        let text = new fabric.Text(txt, {
            // fontFamily: 'gruppo',
            top: Math.abs(CANVAS_HEIGHT * MULTIPLIER * Math.random() - 200),
            left: Math.abs(CANVAS_WIDTH * MULTIPLIER * Math.random() - 200),
            fontSize: 50,
            backgroundColor: color,
            fill: 'white',
            selectable: false,
            hoverCursor: "pointer"

        })

        text.on("mousedown", () => {
            window.open(url)
            window.focus()
        })

        text.scale(0.2)

        canvas.add(text)
    }


    // corner markers
    // let num_markers = [ ...Array(4).keys() ]
    // let markers = []
    // markers[0] = new fabric.Rect({ fill: 'red', width:20, height:20, top:0, left:0 })
    // markers[1] = new fabric.Rect({ fill: 'red', width:20, height:20, top:CANVAS_HEIGHT*2 -20, left:0 })
    // markers[2] = new fabric.Rect({ fill: 'red', width:20, height:20, top:0, left:CANVAS_WIDTH*2 -20})
    // markers[3] = new fabric.Rect({ fill: 'red', width:20, height:20, top:CANVAS_HEIGHT*2-20, left:CANVAS_WIDTH*2 -20})

    // markers.forEach((rect)=>{ canvas.add(rect) })

    // for (let i of numCircles) {
    //     fabric.Image.fromURL(sampleImages[i % sampleImages.length].image, addImg);
    // }

    for (let i of data.sprawl.photos) {
        if(!i.formats){
            continue
        }
        let url = i.formats.small ? i.formats.small.url : i.formats.medium ? i.formats.medium.url : i.formats.thumbnail.url
        fabric.Image.fromURL(url, addImg);
    }


    sleep(9000).then(() => {
        addTxt("created by los_studios", "http://www.losstudios.xyz")
        for (let t of data.sprawlLinks) {
            addTxt(t.Title, t.Url, t.color)
        }
    })

    canvas.on('mouse:down', function (opt) {
        var evt = opt.e;
        //   if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        //@ts-ignore
        this.lastPosX = evt.clientX;
        //@ts-ignore
        this.lastPosY = evt.clientY;
        //   }
    });

    canvas.on('mouse:wheel', function (opt) {
        //@ts-ignore
        var delta = opt.e.deltaY;
        var zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if(zoom < 0.45 ){
            canvas.setZoom(0.45)
            return
        }
        //@ts-ignore
        let p = new fabric.Point(opt.e.clientX, opt.e.clientY)
        canvas.zoomToPoint(p, zoom);
        canvas.setZoom(zoom)
        opt.e.preventDefault();
        opt.e.stopPropagation();
        var vpt = this.viewportTransform;
    })

    canvas.on('mouse:move', function (opt) {
        if (CANVAS_WIDTH < 780) {
            return
        }

        if (this.isDragging) {
            console.log('dragging');
            var e = opt.e;
            var vpt = this.viewportTransform;

            // check box coordinates
            // let vptCords = canvas.vptCoords
            // console.log(JSON.stringify(vptCords));


            //@ts-ignore
            let newX = e.clientX - this.lastPosX;
            //@ts-ignore
            let newY = e.clientY - this.lastPosY
            // if (newX + vpt[4] < 0 && newX + vpt[4] > CANVAS_WIDTH * 2 * -MULTIPLIER) vpt[4] += newX
            // if (newY + vpt[5] < 0 && newY + vpt[5] > CANVAS_HEIGHT * 2 * -MULTIPLIER) vpt[5] += newY
            vpt[4] += newX
            vpt[5] += newY
            this.requestRenderAll();
            //@ts-ignore
            this.lastPosX = e.clientX;
            //@ts-ignore
            this.lastPosY = e.clientY;
        }
    });

    canvas.on('mouse:up', function (opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        console.log('no draggin')
        this.isDragging = false;
        this.selection = true;
        bg.hoverCursor = "grab"
    });

    canvas.setZoom(6.9)
}

export const unlockSprawl = () => {
    const SCALE_FACTOR = 0.25
    console.log('unlocking sprawl')
    let html = document.getElementsByTagName('html')[0]
    html.style.overflow = "scroll"
    let app = document.getElementsByClassName('App')[0]
    //@ts-ignore
    app.style.overflow = "visible"
    // let canv = document.getElementsByTagName("canvas")[0]
    // canv.style.visibility = "visible"
    // canv.style.zIndex = "0";
    let c = document.getElementsByClassName("c")[0]
    //@ts-ignore
    c.style.visibility = "visible";
    //@ts-ignore
    c.style.zIndex = "0";
    localStorage.setItem("sprawl", "true")
}

export const isSprawlLocked = () => {
    let app = document.getElementsByClassName("App")[0]
    if(!app) return true
    //@ts-ignore
    if(app.style.width !== "") return false
    let c = document.getElementsByClassName("c")[0]
    if(!c) return true
    //@ts-ignore
    if(c.style.visibility == "hidden") return true

    return false
}

export const lockSprawl = () => {
    if(isSprawlLocked()) return
    console.log('locking sprawl')
    let html = document.getElementsByTagName('html')[0]
    html.style.overflow = "hidden"
    html.style.fontSize = "100%"
    let app = document.getElementsByClassName('App')[0]
    //@ts-ignore
    app.style.overflow = "hidden"
    // app.style.width = "100%"
    // app.style.height = "100%"
    // app.style.fontSize = "100%"
    // let nav = document.getElementsByClassName('Navigation')[0]
    // console.log(nav)
    // nav.style.fontSize = "100%"

    let c = document.getElementsByClassName("c")[0]
    if(!c) return
    //@ts-ignore
    c.style.visibility = "hidden";
    //@ts-ignore
    c.style.zIndex = "0";

    let sprawl = localStorage.getItem("sprawl")
    if (sprawl) {
        localStorage.removeItem("sprawl")
    }
}

function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}