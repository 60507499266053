import React, { useEffect, useRef, useState } from 'react'
import { navigate, Router } from '@reach/router'

import { fetchStartupData, fetchNavigation, fetchSprawl } from './Logic/api'

import Home from './Pages/Home'
import Portfolio from './Pages/Portfolio'
import BooksPage from './Pages/Books'
import Films from './Pages/Films'
import About from './Pages/About'

import { ColImage, Sections } from './types'
import { setupCanvas, setupCanvasMobile, unlockSprawl } from './sprawl'
import sd, { sampleImages } from '../sampleData'
import Navigation from './components/Navigation'

import "animate.css"
import 'bootstrap/dist/css/bootstrap.min.css';

const getData = async() => {
    let photosData = await fetchStartupData().catch(() => { console.log("unable to get startupdata"); window.location.reload() })
    let navData = await fetchNavigation().catch(() => { console.log("unable to get navigation data"); window.location.reload() })
    return Promise.resolve([photosData,navData])
}

const App = (props: any) => {
    const [loaded, updateLoaded] = useState(false)
    const [nav, updateNav] = useState([] as Array<Sections>)
    const [cols, updateCols] = useState([] as Array<ColImage>)
    const cref = useRef({} as HTMLCanvasElement)
    const reload = () => { window.location.reload() }
    const [gallery, updateGallery] = useState("")
    const [reset, uReset] = useState(false)
    const [data, updateData] = useState({} as any)
    const [fetching, updateFetching] = useState(false)

    const onHover = (gallery: string) => {
        updateGallery(gallery)
    }

    if (!fetching) {
        updateFetching(true)
        
        getData()
            .then((data)=>{
                updateData(data)
                updateCols(data[0])
                updateNav(data[1].sections)
                updateLoaded(true)
            })

        setTimeout(() => {
            console.log("fetching sprawl")
            fetchSprawl()
                .then((sprawlData) => {
                    try {
                        setupCanvas(cref.current, sprawlData)
                    } catch (e) {
                        console.log("something isn't working")
                        console.log(e)
                    }
                })
                .catch(() => { console.log("unable to get sprawl data") })
        }, 4750)
    }

    const updateReset = (r: boolean = false) => {
        uReset(r)
    }

    useEffect(() => {
        if (!loaded) {
        }
    }, [data])

    return (
        <div className="App">
            <Router className="Router">
                <Home default updateLoaded={updateLoaded} gallery={gallery} images={cols} sections={nav} path="/" />
                <Home hover={onHover} updateLoaded={updateLoaded} gallery={gallery} images={cols} sections={nav} path="/sprawl" />
                <Portfolio updateReset={updateReset} path="/photos/:id/:portfolio" reset={reset} />
                <BooksPage path="/books/:id/:books" />
                <Films path="/films" />
                <About path="/about" />
            </Router>
            <Router primary={false} className="NavRouter">
                <Navigation updateReset={updateReset} path="/*" hover={onHover} sections={nav} />
            </Router>
            <div className="c">
                <canvas ref={cref} className="cw" />
            </div>
        </div>
    )
}

export default App
