import React, { useRef, useState } from 'react'
import { HoverImageProps } from '../types'
import { BREAK_POINTS } from '../Config'
import LazyLoad from "react-lazyload"

const HoverImage = (props: HoverImageProps) => {
    const ref = useRef(null)
    const [ loaded,updateLoaded ] = useState( window.innerWidth < 800 ? true: false)

    if(ref.current) ref.current.onload = ()=>{ updateLoaded(true) } 
    if(ref.current && !loaded && ref.current.complete) updateLoaded(true)

    setTimeout(()=>{updateLoaded(true)},2000)

    return (
        <div className="HoverImageWrapper" >
            <img
                ref={ref}
                className={`pimg ${ !loaded ? "blur": ""}`}
                src={!loaded ? props.blurUrl : props.imgUrl}
                alt={`image_${props.idx}`}
            />
                    <div className='pictureDescription'
                    >
                        <div className="picDescText" >
                            {props.desc}
                        </div>
                    </div>
        </div>
    )
}

export default HoverImage


// const HoverImage = (props: HoverImageProps) => {
//     const [desc, showDesc] = useState(false)

//     return (
//         <div className="HoverImageWrapper" >
//             <img
//                 className={`pimg ${desc ? "blur" : ""}`}
//                 src={props.imgUrl}
//                 alt={`image_${props.idx}`}
//                 onMouseEnter={() => { props.desc != "" ? showDesc(true) : ()=>{} }}
//                 onMouseLeave={() => { props.desc != "" ? showDesc(false) : ()=>{} }}
//             />
//             {
//                 desc && (
//                     <div className='pictureDescription'
//                         onMouseEnter={() => { showDesc(true) }}
//                         onMouseLeave={() => { showDesc(false) }}
//                     >
//                         <div className="picDescText" >
//                             {props.desc}
//                         </div>
//                     </div>
//                 )
//             }
//         </div>
//     )
// }