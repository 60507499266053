import React, { useEffect, useState } from 'react'

import ReactMarkdown from 'react-markdown'
import { fetchAbout } from '../Logic/api'
import { lockSprawl } from '../sprawl'
import {AboutData, AboutPageProps} from '../types'

const About = (props: AboutPageProps) => {
    lockSprawl()
    const [loaded,updateLoaded] = useState(false)
    const [data,updateData] = useState({} as AboutData)
    useEffect(()=>{
        if(!loaded){
            fetchAbout()
                .then((data:AboutData)=>{
                    updateData(data)
                    updateLoaded(true)
                })
        }
    })
    return(
        <div className="About">
            <div className="AboutPage">
                {
                    !loaded && (
                        <div id="loading">
                            <div>loading...</div>
                        </div>
                    )
                }
                {
                    loaded && (
                        <div className="AboutData">
                            <ReactMarkdown linkTarget="_blank">
                                {
                                    data.detail
                                }
                            </ReactMarkdown>
                        </div>
                    )
                }
            </div>
        </div>
    )
}


                            // <div id="aboutHeader">
                            //     {data.header}
                            // </div>
                            // <div id="aboutDetail">
                            //     {data.detail}
                            // </div>

export default About