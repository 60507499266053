import * as superagent from 'superagent'
import ql from 'superagent-graphql'
import * as q from './queries'

export const ENDPOINT = "https://cms.nickshaya.com/graphql"

export const fetchStartupData = async () => {
    let resp: superagent.Response = await superagent
    .post(ENDPOINT)
    .use(ql(q.fetchPhotos))
    .catch((err)=>{
        return Promise.reject(err)
    })

    let photos = resp.body.data.galleries

    return photos
}

export const fetchNavigation = async () => {
    let resp: superagent.Response = await superagent
    .post(ENDPOINT)
    .use(ql(q.fetchNavigation))
    .catch((err)=>{
        return Promise.reject(err)
    })

    return resp.body.data

}

export const fetchGallery = async (id:string) => {
    let resp: superagent.Response = await superagent
    .post(ENDPOINT)
    .use(ql(q.fetchGallery(id)))
    .catch((err)=>{
        return Promise.reject(err)
    })

    return resp.body.data.gallery
}


export const fetchSprawl = async () => {
    let resp: superagent.Response = await superagent
    .post(ENDPOINT)
    .use(ql(q.getSprawl))
    .catch((err)=>{
        return Promise.reject(err)
    })

    return resp.body.data
}

export const fetchFilms = async () => {
    let resp: superagent.Response = await superagent
    .post(ENDPOINT)
    .use(ql(q.fetchFilms))
    .catch((err)=>{
        return Promise.reject(err)
    })

    let films = resp.body.data.films

    return films
}

export const fetchAbout = async () => {
    let resp: superagent.Response = await superagent
    .post(ENDPOINT)
    .use(ql(q.fetchAbout))
    .catch((err)=>{
        return Promise.reject(err)
    })

    return resp.body.data.about
}