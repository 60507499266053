import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FiltersMatrix, PhotoColumnProps, PhotoColumnPhotoProps, Filters, ColImage, StrapiPhoto, getImageSize, FormatDetail } from '../types'
import animateScrollTo from 'animated-scroll-to';
import { hex2fem } from '../utilities/rgba2fematrix'
import { navigate } from '@reach/router';
import  PhotoColumnPhoto  from './PhotoColumnPhoto';
import { BREAK_POINTS } from '../Config';

const PhotoColumn = (props: PhotoColumnProps) => {
    const [scroll, updateScroll] = useState(false)
    const [images,updateImages] = useState([])
    const [ loadedImages, updatedLoadedImages ] = useState([])
    const [ unloadedImages, updatedunLoadedImages ] = useState([])
    // const [states,updateState] = useState(new Array(5).fill(true))
    const [states,updateState] = useState([])
    const [maxPhotos,updateMaxPhotos] = useState( 
        window.innerWidth < 800 ? 12 : 5
    )

    useEffect(() => {
        if (!scroll) {
            // if (pc.current) pc.current.scrollTop = Math.random() * pc.current.clientHeight
            if (pc.current) pc.current.scrollTop = 0
            setTimeout(() => {
                animateScrollTo(props.animation.end, {
                    elementToScroll: pc.current,
                    minDuration: 1500
                })
            }, 4200)
            setTimeout(()=>{
                updateMaxPhotos(props.images.length === 0 ? maxPhotos : props.images.length)
            },4000)
        }
    }, [scroll])
    const pc = useRef(null)

    useEffect(()=>{
        if(props.images.length == 0){
            // window.location.reload()
        }
    })

    if(pc.current) pc.current.onscroll = ()=>{
        images.forEach((fn)=>fn())
    }

    const goToGallery = async (img: ColImage) => {
        await navigate(`/${img.gallery.kind}/${img.gallery.id}/${img.gallery.name}`,{replace: true})
    }

    const loadState = (idx: number)=>{
        // let newStates = states.map(s=>s)
        // newStates[idx] = true
        // if(scroll) return
        // updateState(newStates)
        // updateScroll(true)
    }

    const updateFn = (idx:number, fn:()=>boolean) => {
        // images[idx] = fn
        // // setTimeout(()=>{
        //     let inView = fn()
        //     if(inView){
        //         states[idx] = true 
        //         updateState(states)
        //     }
        // // },5000)
    }
    return (
        <div className={`PhotoColumn pc_${props.idx}`} ref={pc}>
            {
                props.images.slice(0,maxPhotos).map((img: ColImage, idx: number) => {
                    let _filter = hex2fem(img.color)
                    // let image = getImageSize(img.formats,img.photo)
                    let image = window.innerWidth <= BREAK_POINTS.small ? img.formats.small : img.formats.large
                    if(!image){
                        return (<div></div>)
                    }
                    return (
                        <PhotoColumnPhoto
                            updateFn={updateFn}
                            key={idx}
                            idx={idx}
                            loadState={loadState}
                            filter={_filter}
                            id={img.gallery.id}
                            gallery={props.gallery}
                            goToGallery={goToGallery}
                            url={image.url }
                            blurUrl = {img.formats.thumbnail.url }
                            img={img}
                            blur={states[idx]}
                        />
                    )
                })
            }
        </div>
    )
}

// filter={ props.gallery === img.gallery.Title ? _filter : Filters.none }
export default React.memo(PhotoColumn)

/*
<div onClick={()=>{goToGallery(img)}} className="cimgc" key={idx}>
        <ImageFilter
            onLoad={()=>{ loadState(idx) }}
            className="cimg"
            image={image.url}
            filter={
                props.gallery === img.gallery.id ?
                _filter :
                FiltersMatrix.none
            }
        />
</div>
*/