import React, { useEffect, useState, useRef } from 'react'
import { fetchGallery } from '../Logic/api'
import Navigation from '../components/Navigation'
import { BookPhotoProps, BooksPageProps, Filters, FormatDetail, PortfolioData } from '../types'
import { lockSprawl } from '../sprawl'
import { getImageSize } from '../types'
import { BREAK_POINTS } from '../Config'

const BooksPage = (props: BooksPageProps) => {
    lockSprawl()
    const [loaded, updateLoaded] = useState(false)
    const [data, updateData] = useState({} as PortfolioData)
    const [gid,updateGID] = useState("")
    const [maxPhotos,updateMaxPhotos] = useState(
        window.innerWidth <= BREAK_POINTS.small ? 3 : 5
    )
    useEffect(() => {
        if (!loaded) {
            fetchGallery(props.id)
                .then((data: PortfolioData) => {
                    updateData(data)
                    updateLoaded(true)
                })
        }
        if(props.id !== gid){
            updateLoaded(false)
            fetchGallery(props.id)
                .then((data: PortfolioData)=>{
                    updateData(data)
                    updateLoaded(true)
                    updateGID(props.id)
                })
        }
    })
    const [color, updateColor] = useState(Filters.none)
    const onHover = (filter: Filters) => {
        updateColor(filter)
    }

    useEffect(()=>{
        if(data.Photos){
            setTimeout(()=>{
                updateMaxPhotos(data.Photos.length)
            },2000)
        }
    },[data])

    return (
        <div className="BooksPage">
                {
                    !loaded && (
                    <div id="loading">
                        <div>loading...</div>
                    </div>
                    )
                }
                {
                    loaded && (
            <div className="Books">
                            <div id="bookInfo">
                                <div id="bookTitle">
                                    {data.Title}
                                </div>
                                <div id="bookDesc">
                                    {data.Description}
                                </div>
                            </div>
                            <div className="bookImages">
                                {
                                    data.Photos.slice(0,maxPhotos).map((item, idx) => {
                                        let image: FormatDetail 
                                        if (window.innerWidth < BREAK_POINTS.large) image = getImageSize(item.formats, item.url)
                                        else image = { url: item.url }
                                        return (
                                            <BookPhoto url={image.url} largeUrl={item.url} blurUrl={item.formats.thumbnail.url} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
        </div>
    )
}

export const BookPhoto = (props: BookPhotoProps) => {
    const ref = useRef(null)
    const [ loaded,updateLoaded ] = useState( window.innerWidth < 800 ? true: false)

    if(ref.current) ref.current.onload = ()=>{ updateLoaded(true) } 
    if(ref.current && !loaded && ref.current.complete) updateLoaded(true)

    setTimeout(()=>{updateLoaded(true)},3000)

    return(
        <img ref={ref} className={`bimg ${!loaded ? "blur" : ""}`} src={!loaded ? props.blurUrl : props.url} />
    )
}

export default BooksPage