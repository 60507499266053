import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { fetchFilms } from '../Logic/api'
import { lockSprawl } from '../sprawl'
import { FilmPageProps, FilmData } from "../types"

const Films = (props:FilmPageProps) => {
    lockSprawl()
    const [loaded,updateLodaed] = useState(false)
    const [films,updateFilms] = useState([] as Array<FilmData>)
    useEffect(()=>{
        if(!loaded){
            fetchFilms()
                .then((film:Array<FilmData>)=>{
                    updateLodaed(true)
                    updateFilms(film)
                })
        }
    })
    return(
        <div className="Films">
            {
                !loaded && (
                    <div id="loading">
                        <div>loading...</div>
                    </div>
                )
            }
            {
                loaded && (
                    <div className="FilmsPage" >
                        {
                            films.map((film: FilmData,idx:number)=>{
                                return(
                                    <div className="Film" key={idx}>
                                        <div id="Film">
                                            <ReactPlayer 
                                            controls={true}
                                            url={film.Url} 
                                            width={"100%"}
                                            height={"100%"}
                                        />
                                        </div>
                                        <div className="FilmInfo">
                                            <div id="FilmTitle">
                                                {film.Title}
                                            </div>
                                            <div id="FilmDesc">
                                                {film.Detail}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Films