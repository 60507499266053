import { navigate, useLocation } from '@reach/router'
import React, { useEffect, useRef, useState } from 'react'
import { NavProps, Sections, Link } from '../types'

import "animate.css"

import Section from './Section'
import { lockSprawl } from '../sprawl'

const Navigation = (props: NavProps) => {

    const [showStaticNav,updateShowStatic] = useState(false)
    const showStatic = () => {
        if(!showStaticNav)  updateShowStatic(true)
    }


    const titleRef = useRef()
    const loc = useLocation()
    let id = loc.pathname.split("/")[2]
    const [refs, updateRefs] = useState([[], []])
    const registerRef = (title: string, ref: HTMLElement) => {
        if (!refs[0].includes(title)) {
            refs[0].push(title)
            refs[1].push(ref)
            updateRefs(refs)
        }
    }
    const blackOut = (cr) => {
        // for (let r of refs[1]) {
        //     if (r.innerHTML != cr) r.style.color = "black" 
        // }
        setTimeout(() =>{
            for (let r of refs[1]) if(r.innerHTML != cr) r.style.color = "black"
        },100)
    }

    const aboutInvert = () => {
        // @ts-ignore
        if (titleRef.current) titleRef.current.style.filter = "invert(1)"
    }

    const aboutRevert = () => {
        // @ts-ignore
        if (titleRef.current) titleRef.current.style.filter = "invert(0)"
    }

    const mobile = window.innerWidth <= 780 ? true : false


    //drop down menu for mobile
    const [showDropDown, updateShowDropDown] = useState(false)
    const dropDown = useRef

    const closeNav = () => {
        updateShowDropDown(false)
        props.updateReset(true)
    }

    return (
        <div className="Navigation">
            {
                mobile && (
                    <div id="NavContainer">
                        <div ref={titleRef} id="title" onClick={async () => { lockSprawl(); blackOut(""); await navigate('/') }} >
                            nicholas shaya
                        </div>
                        <div id="outer-container" onClick={() => { updateShowDropDown(!showDropDown) }}>
                            menu
                        </div>
                        {
                            showDropDown && (
                                <div className="DropDownMobile">
                                    <div className="DropDownAnimated animate__animated animate__slideInRight">
                                        <div className="DropDownContainer">
                                    {
                                        props.sections.map((section: Sections, idx: number) => {
                                            if(idx === props.sections.length - 1) showStatic()
                                            return (
                                                <div key={idx}>
                                                    <Section closeNav={closeNav} registerRef={registerRef} blackOut={blackOut} hover={props.hover} section={section} gallery={id} />
                                                    <div id="navBreak"></div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        showStaticNav && (
                                            <div>
                                                    <div
                                                        className="SprawlButton Section"
                                                        onClick={async () => {
                                                            updateShowDropDown(false)
                                                            blackOut("")
                                                            await navigate("/about")
                                                            aboutRevert()
                                                        }}
                                                        onMouseEnter={aboutInvert}
                                                        onMouseLeave={aboutRevert}
                                                    >about</div>
                                                    <div className="SprawlButton Section" onClick={async () => {
                                                        blackOut("")
                                                        updateShowDropDown(false)
                                                        await navigate("/sprawl")
                                                    }}>sprawl</div>
                                            </div>
                                        )
                                    }
                                </div>
                                </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                !mobile && (
                    <div>
                        <div
                            ref={titleRef}
                            id="title"
                            onClick={() => {
                                // lockSprawl();
                                window.location.href = "/"
                                // blackOut("") navigate('/')
                            }}
                        >
                            nicholas shaya
            </div>
                        {
                            props.sections.map((section: Sections, idx: number) => {
                                if(idx === props.sections.length - 1) showStatic()
                                return (
                                    <div key={idx}>
                                        <Section closeNav={closeNav} registerRef={registerRef} blackOut={blackOut} hover={props.hover} section={section} gallery={id} />
                                        <div id="navBreak"></div>
                                    </div>
                                )
                            })
                        }
                                    {
                                        showStaticNav && (
                                            <div>
                                                    <div
                                                        className="SprawlButton Section"
                                                        onClick={async () => {
                                                            updateShowDropDown(false)
                                                            blackOut("")
                                                            await navigate("/about")
                                                            aboutRevert()
                                                        }}
                                                        onMouseEnter={aboutInvert}
                                                        onMouseLeave={aboutRevert}
                                                    >about</div>
                                                    <div className="SprawlButton Section" onClick={async () => {
                                                        blackOut("")
                                                        updateShowDropDown(false)
                                                        await navigate("/sprawl")
                                                    }}>sprawl</div>
                                            </div>
                                        )
                                    }
                    </div>
                )
            }
        </div>
    )
}


export default Navigation
